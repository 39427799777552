import React from "react";
import { api } from "../../utils";

const Header = ({ searchName, setSearchName }: any) => {
  return (
    <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
      <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">
        Ahlul-Quran
      </a>
      <button
        className="navbar-toggler position-absolute d-md-none collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <input
        className="form-control form-control-dark w-100"
        type="text"
        placeholder="Search"
        aria-label="Search"
        value={searchName}
        onChange={(e) => setSearchName(e.target.value)}
      />
      <ul className="navbar-nav px-3">
        <li
          className="nav-item text-nowrap"
          onClick={() => {
            window.localStorage.removeItem("token");
            window.location.assign("/");
          }}
        >
          <a className="nav-link" href="/">
            Sign out
          </a>
        </li>
      </ul>
    </header>
  );
};

export default Header;
