import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Axios from "axios";
import { SiAddthis } from "react-icons/si";
import { useForm } from "react-hook-form";
import { Student } from "../../lib";
import { useStore } from "../../store";
import withReactContent from "sweetalert2-react-content";
import { StudentDetails } from "./StudentDetails";
const MySwal = withReactContent(Swal);

interface Props {
  searchName: string;
  setSearchName: React.Dispatch<React.SetStateAction<string>>;
}

const Main = ({ searchName }: Props) => {
  const students = useStore((state) => state.students);
  const filteredStudents = useStore((state) => state.filteredStudents);
  const [allStudents, setAllStudents] = useState<Student[]>(
    useStore((state) => state.students)
  );
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const { register, handleSubmit } = useForm();
  const loadingStudents = useStore((state) => state.loading);
  const add = useStore((state) => state.add);
  const get = useStore((state) => state.get);

  useEffect(() => {
    console.log("HELLO");
    setAllStudents(students);
  }, [students]);

  const onSubmit = async (data: any) => {
    let from;
    let to;
    if (!data.to) {
      from = new Date(data.from).setHours(0, 0, 0, 0);
      to = new Date(data.from).setHours(23, 59, 59, 59);
    } else {
      from = new Date(data.from).setHours(0, 0, 0, 0);
      to = new Date(data.to).setHours(23, 59, 59, 59);
    }
    try {
      const res = await get(`student?from=${from}&to=${to}`);
      if (res.operation === "failed") {
        alert("ERROR");
        return;
      }
      console.log(res);
    } catch (e) {
      console.log("KHALAD COMP: ", e);
    }
  };

  const addSerialNumber = (student: Student) => {
    const id: string | undefined = student._id;
    delete student._id; // delete key from object!.
    console.log(student);
    Swal.fire({
      title: "Add serial number",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Add",
      showLoaderOnConfirm: true,
      preConfirm: async (serial: string) => {
        student.serialNumber = serial;
        const resp = await add(`student?id=${id}`, student);
        console.log("COMP: ", resp);
        if (resp.operation === "failed") {
          return Swal.showValidationMessage(`${resp.errors[0]}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `Added Successfully!`,
        }).then((confirm) => {
          if (confirm.isConfirmed) {
            (async () => {
              await get("student");
            })();
          }
        });
      }
    });
  };

  return (
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Dashboard</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="mr-5">
          <div className="row">
            <div className="col">
              <input
                type="date"
                className="form-control"
                placeholder="From"
                {...register("from", { required: true })}
              />
            </div>
            <div className="col">
              <input
                type="date"
                className="form-control"
                placeholder="To"
                {...register("to")}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Search
            </button>
          </div>
        </form>
      </div>
      <div className="d-flex justify-content-between align-items-center py-3">
        <h2>
          Registered People :{" "}
          <span style={{ fontSize: "1.5rem" }}>{allStudents?.length}</span>
        </h2>
        <button
          className="btn btn-primary mr-4"
          onClick={async () => {
            await get("student");
            // setAllStudents(allStudents);
          }}
        >
          Reset
        </button>
      </div>
      <div className="table-responsive">
        {loadingStudents ? (
          <h1>Loading....</h1>
        ) : notFound ? (
          <h5>Nothing found on that date</h5>
        ) : (
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>phone</th>
                <th>Whatsapp No</th>
                <th>Country</th>
                <th>Gender</th>
                <th>Date</th>
                <th>Teacher</th>
                <th>Course</th>
                <th>Serial Number</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {allStudents && allStudents?.length > 0 ? (
                allStudents
                  .filter((s) => s.name.includes(searchName))
                  .map((user: Student, idx: number) => (
                    <tr key={idx}>
                      <td>{idx}</td>
                      <td>{user.name}</td>
                      <td>{user.contactNumber}</td>
                      <td>{user.whatsappNumber}</td>
                      <td>{user.country}</td>
                      <td>{user.gender}</td>
                      <td>
                        {new Date(user.registeredDate).toLocaleDateString()}
                      </td>
                      <td>{user.teacher}</td>
                      <td>{user.course}</td>
                      <td>
                        {user.serialNumber ? (
                          user.serialNumber
                        ) : (
                          <>
                            <SiAddthis
                              style={{
                                color: "green",
                                fontSize: "2rem",
                                cursor: "pointer",
                              }}
                              onClick={() => addSerialNumber(user)}
                            />
                          </>
                        )}
                      </td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            MySwal.fire({
                              showCloseButton: true,
                              width: 1200,
                              showConfirmButton: false,
                              allowOutsideClick: false,
                              html: <StudentDetails student={user} />,
                            });
                          }}
                        >
                          Show
                        </button>
                      </td>
                    </tr>
                  ))
              ) : (
                <h5>No Students found !!</h5>
              )}
            </tbody>
          </table>
        )}
      </div>
    </main>
  );
};

export default Main;
