import create from 'zustand';
import Axios from 'axios';
import { ServerResponse, Student, User } from '../lib';
import { api } from '../utils';



export const useStore = create(set => ({
    loading: false,
    students: [],
    authorizationHeader: "",
    filteredStudents: [],
    get: (path: string) => get(set, path),
    add: (path: string, body: Student) => add(set, path, body),
    login: (path: string, body: Partial<User>) => login(set, path, body),
    me: (path: string) => me(set, path),
}));

const me = async (set: any, path: string): Promise<ServerResponse> => {
    try {
        const res = await api.get(`/${path}`);
        return res.data;
    } catch (error) {
        console.log(error);
        return {
            operation: 'failed',
            statusCode: '',
            errors: ['Network Error']
        }
    }
}

const get = async (set: any, path?: string): Promise<ServerResponse> => {
    try {
        const filter = path?.includes("from");

        set({ loading: true });
        const resp = await api.get(`/${path?.trim()}`);
        console.log("STORE :", resp);
        // set(filter ? { filteredStudents: resp.data.data } : { students: resp.data.data });
        set({ students: resp.data.data });
        set({ loading: false });
        return resp.data;
    } catch (error) {
        console.log("KHALD: ", error);
        set({ loading: false });
        return {
            operation: 'failed',
            statusCode: '',
            errors: ['Network error'], // network error mostly mean server is down.
        }
    }
}

const add = async (set: any, path: string, body: Student): Promise<ServerResponse> => {
    try {
        const resp = await api.post(`/${path}`, body);
        return resp.data;
    } catch (error) {
        return {
            operation: "failed",
            statusCode: "",
            errors: [
                "Network error"
            ]
        };
    }
}

const login = async (set: any, path: string, body: Partial<User>): Promise<ServerResponse> => {
    try {
        const resp = await Axios.post(`https://ahlulquran-v2.herokuapp.com/api${path}`, body);
        return resp.data;
    } catch (error) {
        return {
            operation: "failed",
            statusCode: "",
            errors: [
                "Network error login"
            ]
        }
    }

}