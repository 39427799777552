import Axios from "axios";


const token = window.localStorage.getItem('token') ? localStorage.getItem('token') : ""
export const api = Axios.create({
    withCredentials: true,
    baseURL: "https://ahlulquran-v2.herokuapp.com/api",
    headers: {
        'authorization': token
    }
});
