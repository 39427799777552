import react, { useEffect, useState } from "react";
import Header from "./Header";
import Main from "./Main";
import Nav from "./Nav";
import SideBar from "./SideBar";
import Swal from "sweetalert2";
import Axios from "axios";
import Cookie from "js-cookie";
import { useNavigate } from "react-router";
import { useStore } from "../../store";
import { api } from "../../utils";

export const Dashboard = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(true);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchName, setSearchName] = useState("");
  const me = useStore((state) => state.me);
  const get = useStore((state) => state.get);

  useEffect(() => {
    if (document.getElementById("root")?.style.paddingTop) {
      document.getElementById("root")!.style.paddingTop = "0px";
    }

    (async () => {
      const res = await me("auth/me");
      if (res.operation === "failed") {
        return setAuth(false);
      }
      setLoading(false);
      const resp = await get("student");
      if (resp.operation !== "successful") {
        return alert(resp.errors[0]);
      }
      setUsers(resp.data);
    })();
  }, []);

  const Loader: JSX.Element = (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h1>Loading ....</h1>
    </div>
  );

  if (auth) {
    return loading ? (
      Loader
    ) : (
      <>
        <Header searchName={searchName} setSearchName={setSearchName} />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <Main
              // users={users}
              searchName={searchName}
              setSearchName={setSearchName}
            />
          </div>
        </div>
      </>
    );
  } else {
    Swal.fire({
      title: "Error!",
      text: "Please login  in with user/password before procceding to this page!",
      icon: "error",
      confirmButtonText: "OK",
    }).then((res) => {
      if (res.isConfirmed) {
        window.location.replace("/");
      }
    });
    return <h1></h1>;
  }
};
