import React from 'react'
import Swal from 'sweetalert2'
import { Student } from '../../lib'


interface Props {
    student: Student
}
export const StudentDetails = ({ student }: Props) => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    return (
        <div className="row invoice row-printable">
            <div className="col-md-12">
                {/* col-lg-12 start here */}
                <div className="panel panel-default plain" id="dash_0">
                    {/* Start .panel */}
                    <div className="panel-body p30">
                        <div className="row">
                            {/* Start .row */}
                            <div className="col-lg-4 text-left">
                                {/* col-lg-6 start here */}
                                <div className="invoice-logo"><img width={100} src="/assets/images/logoo.png" alt="Invoice logo" /></div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <h4>AhlulQuran Academy</h4>
                            </div>
                            {/* col-lg-6 end here */}
                            <div className="col-lg-4">
                                {/* col-lg-6 start here */}
                                <div className="invoice-from">
                                    <ul className="list-unstyled text-right">
                                        <li>{student?.serialNumber}</li>
                                        <li>{student?.name}</li>
                                        <li>{student?.email}</li>
                                        <li>{student?.country}</li>
                                        <li>{student?.city}</li>
                                    </ul>
                                </div>
                            </div>
                            {/* col-lg-6 end here */}
                            <div className="col-lg-12 text-left">
                                {/* col-lg-12 start here */}
                                <div className="invoice-details mt25">
                                    <div className="well w-100 d-flex justify-content-center">
                                        <ul className="list-unstyled mb0 w-75 d-flex flex-wrap">
                                            <div style={{ flexGrow: 1 }}>
                                                <li><strong>Name:</strong> {student?.name}</li>
                                                <li><strong>Date of birth:</strong> {student?.dob}</li>
                                                <li><strong>Gender:</strong> {student?.gender}</li>
                                            </div>
                                            <div>
                                                <li><strong>Registered Date:</strong> {new Date(student?.registeredDate).toLocaleDateString()}</li>
                                                <li><strong>Contact Number:</strong> {student?.whatsappNumber}</li>
                                                <li><strong>Whatsapp Number:</strong> {student?.contactNumber}</li>
                                            </div>
                                            {/* <li><strong>Status:</strong> <span className="label label-danger">UNPAID</span></li> */}
                                        </ul>
                                    </div>
                                </div>
                                {
                                    student?.children.length > 0 &&
                                    <div className="invoice-items">
                                        <div className="table-responsive" style={{ overflow: 'hidden', outline: 'none' }} tabIndex={0}>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th className="per70 text-center">first name</th>
                                                        <th className="per5 text-center">last Name</th>
                                                        <th className="per25 text-center">Age</th>
                                                        <th className="per25 text-center">Nationality</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        student?.children.map((c: any, idx) => (
                                                            <tr key={idx}>
                                                                <td>{c["forename" + idx]}</td>
                                                                <td>{c["surname" + idx]}</td>
                                                                <td>{c["age" + idx]}</td>
                                                                <td>{c["nationalty" + idx]}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }

                                <div className="invoice-to mt25">
                                    <ul className="list-unstyled">
                                        <li><strong>Course : </strong>{student?.course}</li>
                                        <li><strong>Days : </strong>{student?.days.toString()}</li>
                                        <li><strong>Teacher : </strong>{student?.teacher}</li>
                                    </ul>
                                </div>
                                <div className="invoice-footer mt25">
                                    <p className="text-center">{`Generated on ${days[new Date().getDay()]}, ${months[new Date().getMonth()]} ${new Date().getDate()}, ${new Date().getFullYear()}`}<a href="#" className="btn btn-default ml15"><i className="fa fa-print mr5" /> Print</a></p>
                                </div>
                            </div>
                            {/* col-lg-12 end here */}
                        </div>
                        {/* End .row */}
                    </div>
                </div>
                {/* End .panel */}
            </div>
            {/* col-lg-12 end here */}
        </div>

    )
}
